import React from 'react';

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@material-ui/lab';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';

import MintIcon from '@mui/icons-material/Cached';
import ListingIcon from '@mui/icons-material/CompareArrows';
import HoneyIcon from '@mui/icons-material/LocalPostOffice';
import CharityIcon from '@mui/icons-material/EmojiNature';
import CommunityIcon from '@mui/icons-material/GroupAdd';
import MerchIcon from '@mui/icons-material/Shop';
import PlatformIcon from '@mui/icons-material/Pets';
import RoadMapBackground from './roadmap_background';


// eslint-disable-next-line require-jsdoc
export default function RoadMap() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Box id="roadmap" sx={ theme.custom.lightSectionStyle }>

            <RoadMapBackground />
            <Container maxWidth="md" sx={ { py: 7 } }>
                <Typography variant="h2" component="div" gutterBottom align="center">
                    Roadmap
                </Typography>
                <Timeline align={ matches ? 'alternate' : 'left' } position={ matches ? 'alternate' : 'right' }>
                    <TimelineItem>
                        <TimelineOppositeContent
                            sx={ { m: 'auto 0' } }
                            align="right"
                            variant="body2"
                            color="text.secondary"
                        >
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <MintIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                Mint
                            </Typography>
                            <Typography>100% fair and eco-friendly mint & initial sale of BuzzClub Bees</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <ListingIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                Listing
                            </Typography>
                            <Typography>Verified listing on MagicEden & DigitalEyes</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <HoneyIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                Honey
                            </Typography>
                            <Typography>International shipping of our honey to all queen bee minters</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <CharityIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                Charity
                            </Typography>
                            <Typography> Community vote on charity to receive donations from initial sale #SaveTheBees</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <CommunityIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                Observe your bees
                            </Typography>
                            <Typography>  Launch of exclusive observer platform for BuzzClub bee holders incl. Live Webcam of our hives,
                                beekeeper updates about your adopted bee and much more!</Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <MerchIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                Individualized Merch
                            </Typography>
                            <Typography> Individualized honey with your personal BuzzClub bee on it.
                                Imagine yourself with the story how you helped saving bees on your personalized jar of honey.
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                        <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="secondary">
                                <PlatformIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={ { py: '12px', minWidth: '250px' } }>
                            <Typography variant="h6" component="span">
                                NFTs and Nature
                            </Typography>
                            <Typography> In the future we will continue to explore new ways of connecting local farmers with consumers.
                                E.g. by allowing consumers to “adopt” real farm animals &amp; plants and participate in their yield.
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Container>

        </Box>
    );
}
