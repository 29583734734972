import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useTheme, Button, Tooltip, Dialog, useMediaQuery } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { StaticImage } from 'gatsby-plugin-image';
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/react';

import RarityRanking from './rarity/rarity-ranking';

const shine = keyframes`
0% {
  transform:translateX(-100%) translateY(-100%) rotate(30deg);
}
80% {transform:translateX(-100%) translateY(-100%) rotate(30deg);}
100% {transform:translateX(100%) translateY(100%) rotate(30deg);}
`;

const hexContainer1 = css`
overflow: hidden;
visibility: hidden;
-webkit-transform: rotate(120deg);
-moz-transform: rotate(120deg);
-ms-transform: rotate(120deg);
-o-transform: rotate(120deg);
transform: rotate(120deg);
width: 100%;
height: 100%;
`;

const hexContainer2 = css`
overflow: hidden;
width: 100%;
height: 100%;
-webkit-transform: rotate(-60deg);
-moz-transform: rotate(-60deg);
-ms-transform: rotate(-60deg);
-o-transform: rotate(-60deg);
transform: rotate(-60deg);
`;

const hexContainer3 = css`
overflow:hidden;
width: 100%;
height: 100%;
background-repeat: no-repeat;
background-position: 50%;
visibility: visible;
-webkit-transform: rotate(-60deg);
-moz-transform: rotate(-60deg);
-ms-transform: rotate(-60deg);
-o-transform: rotate(-60deg);
transform: rotate(-60deg);
`;

const hexContainer4 = css`
&:after {
  content:'';
  top:0;
  left:-100px;
  transform:translateX(100%) rotate(30deg);
  width:300%;
  height:300%;
  position: absolute;
  z-index:1;
  animation: ${ shine } 3s infinite ease-in;
  background:
    linear-gradient( to right, 
      rgba(255,255,255,0) 0%,
      rgba(255,255,255,0.8) 50%,
      rgba(128,186,232,0) 99%,
      rgba(128,186,232,0) 100%);
}
`;

const hexContainer4Delay1 = css`
${ hexContainer4 }
&:after {animation-delay: 1s;}
`;
const hexContainer4Delay2 = css`
${ hexContainer4 }
&:after {animation-delay: 2s;}
`;

// eslint-disable-next-line require-jsdoc
export default function Rarity() {
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <Container id="rarity" maxWidth={ false } sx={ theme.custom.lightSectionStyle } >
            <Container maxWidth="lg" sx={ { py: 7 } }>
                <Grid container spacing={ 10 }>
                    <Grid item sm={ 8 } container alignItems="center">
                        <Grid item sm={ 4 } sx={ { pb: 3 } }>
                            <Tooltip title="The queen bee is the leader of all bees in the colony. The worker bees care and nurture her attentively. Without her, they are all lost. The queen bee leaves the hive only at the beginning of her life to mate with 12-20 drones.">
                                <Container>
                                    <Box sx={ { pb: 3 } }>
                                        <div css={ hexContainer1 }>
                                            <div css={ hexContainer2 }>
                                                <div css={ hexContainer3 }>
                                                    <div css={ hexContainer4 }>
                                                        <StaticImage
                                                            src="../images/queen1_hexagon_cropped.png"
                                                            alt="a bee..."
                                                            placeholder="blurred"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>

                                    <Typography sx={ theme.custom.boldTextLargeCentered }>
                                        Queen Bee
                                    </Typography>
                                    <Typography sx={ theme.custom.italicTextCentered }>
                                        super rare &#126;1%
                                    </Typography>
                                </Container>
                            </Tooltip>
                        </Grid>
                        <Grid item sm={ 4 } sx={ { pb: 3 } }>
                            <Tooltip title="The male drone bees have only one purpose: to mate with a virgin queen from another colony. In their home hive, the male drone does not do any work and is fed by his sister worker bees. Only on sunny days, the drone will fly out, in hope of finding a rare virgin bee to mate with.">
                                <Container>
                                    <Box sx={ { pb: 3 } }>
                                        <div css={ hexContainer1 }>
                                            <div css={ hexContainer2 }>
                                                <div css={ hexContainer3 }>
                                                    <div css={ hexContainer4Delay1 }>
                                                        <StaticImage
                                                            src="../images/drone1_hexagon_cropped.png"
                                                            alt="a bee..."
                                                            placeholder="blurred"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                    <Typography sx={ theme.custom.boldTextLargeCentered }>
                                        Drone Bee
                                    </Typography>
                                    <Typography sx={ theme.custom.italicTextCentered }>
                                        rare &#126;10%
                                    </Typography>
                                </Container>
                            </Tooltip >
                        </Grid >
                        <Grid item sm={ 4 } sx={ { pb: 3 } }>
                            <Tooltip enterDelay={ 50 } leaveDelay={ 1000 } title="The worker bees grind day and night: They collect honey, feed the queen & drones and build, clean & protect the hive.">
                                <Container >
                                    <Box sx={ { pb: 3 } }>
                                        <div css={ hexContainer1 }>
                                            <div css={ hexContainer2 }>
                                                <div css={ hexContainer3 }>
                                                    <div css={ hexContainer4Delay2 }>
                                                        <StaticImage
                                                            src="../images/bee2_hexagon_cropped.png"
                                                            alt="a bee..."
                                                            placeholder="blurred"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                    <Typography sx={ theme.custom.boldTextLargeCentered }>
                                        Worker Bee
                                    </Typography>
                                    <Typography sx={ theme.custom.italicTextCentered }>
                                        common
                                    </Typography>
                                </Container>
                            </Tooltip>
                        </Grid>
                    </Grid>

                    <Grid item sm={ 4 } container alignItems="center">
                        <Box sx={ { p: 1 } }>
                            <Typography variant="h3" component="div" gutterBottom>
                                HOW RARE?
                            </Typography>
                            <Typography variant="body1" component="div" gutterBottom>
                                BuzzClub has 3 types of members: Queens, Drones and Workers.
                                Each of our 480 members is unique and randomly generated from over 120 possible traits,
                                including eyes, facial expressions, wings and many more.
                                In the club, there are only 6 queens, each representing
                                the leader in one of our real colonies.
                                The 6 lucky owners minting a queen bee were shipped a
                                jar of honey from their queen&apos;s colony.
                            </Typography>
                            <Button onClick={ handleOpen } variant="contained" sx={ theme.custom.darkButton }>Check Trait Rarities</Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Dialog onClose={ handleClose } open={ open } fullScreen={ true }>
                <DialogActions >
                    <Button autoFocus onClick={ handleClose } sx={ theme.custom.darkButton } variant='contained'>
                        Close
                    </Button>
                </DialogActions>
                <RarityRanking />
            </Dialog>
        </Container >

    );
}