import { Container, Grid, Typography, useTheme } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function RoadMap() {
    const rowSize = 2;
    const theme = useTheme();
    return (
        <Container maxWidth={false} id="team" sx={theme.custom.darkSectionStyle} >
            <Container maxWidth='lg' sx={{ py: 7 }}>
                <Typography variant="h2" component="div" gutterBottom align="center">
                    Team
                </Typography>
                <Grid container spacing={4}>
                    <Grid item sm={rowSize}>
                        <Container sx={{ pb: 2 }}>
                            <StaticImage
                                src="../images/keeper1.png"
                                alt="a bee..."
                                placeholder="blurred"
                            />
                        </Container>
                        <Typography sx={theme.custom.boldTextLargeCentered}>
                            BusyBee
                        </Typography>
                        <Typography sx={theme.custom.textCentered}>
                            Passionate beekeeper, developer and crypto enthusiast. Looking for ways to bring the crypto community closer to nature.
                        </Typography>
                    </Grid>
                    <Grid item sm={rowSize}>
                        <Container sx={{ pb: 2 }}>
                            <StaticImage
                                src="../images/keeper2.png"
                                alt="a bee..."
                                placeholder="blurred"
                            />
                        </Container>
                        <Typography sx={theme.custom.boldTextLargeCentered}>
                            RoBee
                        </Typography>
                        <Typography sx={theme.custom.textCentered}>
                            Pro Beekeeper and blockchain geek, taking care of the BuzzClub hives day and night.
                        </Typography>
                    </Grid>
                    <Grid item sm={rowSize}>
                        <Container sx={{ pb: 2 }}>
                            <StaticImage
                                src="../images/keeper3.png"
                                alt="a bee..."
                                placeholder="blurred"
                            />
                        </Container>
                        <Typography sx={theme.custom.boldTextLargeCentered}>
                            HackyBee
                        </Typography>
                        <Typography sx={theme.custom.textCentered}>
                            Full stack developer and crypto hacker, ensuring that the project sails smoothly on its journey.
                        </Typography>
                    </Grid>
                    <Grid item sm={rowSize}>
                        <Container sx={{ pb: 2 }}>
                            <StaticImage
                                src="../images/keeper4.png"
                                alt="a bee..."
                                placeholder="blurred"
                            />
                        </Container>
                        <Typography sx={theme.custom.boldTextLargeCentered}>
                            ArtsyBee
                        </Typography>
                        <Typography sx={theme.custom.textCentered}>
                            Experienced illustrator and concept artist. He is the creative genius behind many successful mobile games.
                        </Typography>
                    </Grid>
                    <Grid item sm={rowSize}>
                        <Container sx={{ pb: 2 }}>
                            <StaticImage
                                src="../images/keeper5.png"
                                alt="a bee..."
                                placeholder="blurred"
                            />
                        </Container>
                        <Typography sx={theme.custom.boldTextLargeCentered}>
                            BeeBoy
                        </Typography>
                        <Typography sx={theme.custom.textCentered}>
                            Community manager nurturing our online bee family.
                        </Typography>
                    </Grid>
                    <Grid item sm={rowSize}>
                        <Container sx={{ pb: 2 }}>
                            <StaticImage
                                src="../images/keeper6.png"
                                alt="a bee..."
                                placeholder="blurred"
                            />
                        </Container>
                        <Typography sx={theme.custom.boldTextLargeCentered}>
                            FarmBee
                        </Typography>
                        <Typography sx={theme.custom.textCentered}>
                            Farmer, hosting our headquarters. She yields rewards on DeFi as well as apples, nuts &amp; eggs on her farm in real life.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
