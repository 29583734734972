import React from 'react';

/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/react';
import { StaticImage } from 'gatsby-plugin-image';


const rotate = keyframes`
0% {transform: rotateX(0);}
45% {transform: rotateX(0deg);}
50% {transform: rotateX(180deg);}
95% {transform: rotateX(180deg);}
100% {transform: rotateX(360deg);}
`;

const flipBox = css`
width: 100%;
height: 100%;
background-color: transparent;
perspective: 1000px; /* Remove this if you don't want the 3D effect */
`;

const flipBoxInner = css`
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
animation: ${ rotate } 5s infinite linear;
`;
const flipBoxFront = css`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  color: black;

`;
const flipBoxBack = css`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  color: white;
  transform: rotateX(180deg);

`;

const imgBackground = css`img {-webkit-backface-visibility: hidden;}`;

// eslint-disable-next-line require-jsdoc
export default function IntroHexagonImage() {
    return (
        <div css={ flipBox }>
            <div css={ flipBoxInner }>
                <div css={ flipBoxFront }>
                    <StaticImage style={ { position: 'static' } } css={ imgBackground }
                        src="../images/bee1_hexagon.png"
                        alt="a bee..."
                        placeholder="blurred"
                        objectFit='contain'
                    />
                </div>
                <div css={ flipBoxBack }>
                    <StaticImage style={ { position: 'static' } }
                        src="../images/real_bee.png"
                        alt="a bee..."
                        placeholder="blurred"
                        objectFit='contain'
                    />
                </div>
            </div>
        </div>
    );
}
