import * as React from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import theme from '../modules/theme';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';


// eslint-disable-next-line require-jsdoc
export default function Landing() {
    const customBoldTextLargeCentered = { ...theme.custom.boldTextLargeCentered, py: 1 };

    const { mobileImage, desktopImage } = useStaticQuery(
        graphql`
          query {
            mobileImage: file(relativePath: { eq: "banner_mobile.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 750
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                }
              }
              desktopImage: file(relativePath: { eq: "banner3.jpg" }) {
                childImageSharp {
                    gatsbyImageData(
                        width: 2560
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                }
              }
          }
        `,
    );

    const sources = [
        getImage(mobileImage),
        {
            ...getImage(desktopImage),
            media: `(min-width: 491px)`,
        },
    ];

    return (
        <Container disableGutters maxWidth={ false } >
            <BgImage image={ sources }>
                <Container disableGutters maxWidth={ false } sx={ { height: '75vh' } }>
                    <Grid container spacing={ 1 }>
                        <Grid item sm={ 12 } container justifyContent="center" alignItems="center" >
                            <Box sx={ { py: 5, px: 1, position: 'absolute', bottom: 0 } }>
                                <Paper sx={ { p: 3, height: 'fit-content', borderRadius: 3, maxWidth: '350px' } }>
                                    <Box sx={ { display: 'flex', justifyContent: 'center' } }>
                                    </Box>
                                    <Typography variant="h4" component="div" align="center">
                                        Adopt a real bee
                                    </Typography>
                                    <Typography variant="body1" component="div" align="center" sx={ { py: 3 } }>
                                        480 unique NFTs, each representing a real bee in one of our hives.
                                        Join the BuzzClub community and help us save the bees!
                                    </Typography>
                                    <Box sx={ { justifyContent: 'center', display: 'flex' } }>
                                        <Button variant="contained" sx={ theme.custom.darkButton } href="https://magiceden.io/marketplace/buzzclub_nft" target="_blank" >
                                            Let Me Adopt
                                        </Button>
                                    </Box>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid >
                </Container>
            </BgImage >
        </Container >
    );
}
