import * as React from 'react';
import { useTheme, Container, Button, Grid, Table, TableHead, TableRow, TableCell, TableBody, Input, OutlinedInput, InputLabel, FormControl, Box, Typography, Stack, TextField } from '@mui/material';
import { graphql, StaticQuery } from 'gatsby';


// eslint-disable-next-line react/display-name
export default () => (
    <StaticQuery
        query={ graphql`
      query AllRanking{
        allRankingCsv {
          nodes {
            ...RankingCsvFragment
          }
        }
      }
    `}
        render={ (data) => <Ranking data={ data } /> }
    />
);

const Ranking = ({ data }) => {
    const theme = useTheme();

    const [currentBeeNumber, setCurrentBeeNumber] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    const handleClose = (value) => {
        setCurrentBeeNumber(inputValue);
    };

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <Container maxWidth={ false } id="raritygrid" >

            <Stack direction="row" spacing={ 2 }>
                <FormControl>
                    <TextField
                        id="component-outlined"
                        onChange={ handleChange }
                        label="Bee Number"
                        color="secondary" focused
                    />
                </FormControl>
                <Button onClick={ handleClose } sx={ theme.custom.darkButton } variant='contained'>Search</Button>
            </Stack>
            { data.allRankingCsv.nodes.length > 0 &&
                data.allRankingCsv.nodes.filter(bee => bee.index == currentBeeNumber).map(bee => {
                    return (
                        <div key="0">
                            <Grid container spacing={ 10 }>
                                <Grid item sm={ 12 } sx={ { pb: 3 } }>
                                    <Container>
                                        <Typography variant="h3" sx={ theme.custom.boldTextLargeCentered }>
                                            Your BuzzClub Bee # { bee.index }
                                        </Typography>
                                    </Container>
                                </Grid>
                                <Grid item sm={ 6 } sx={ { pb: 3 } }>
                                    <Container sx={ { height: '100%', width: '100%' } }>
                                        <img style={ { width: '100%' } } src={ bee.ImageUri } />
                                    </Container>
                                </Grid>
                                <Grid item sm={ 6 } sx={ { pb: 3 } }>
                                    <Container>
                                        <Grid container spacing={ 10 }>
                                            <Grid item sm={ 12 } sx={ { pb: 1 } }>
                                                <Typography sx={ theme.custom.boldTextLarge }>
                                                    Overall rank { bee.Rank.substring(0, bee.Rank.length - 2) }/480
                                                </Typography>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Type</TableCell>
                                                            <TableCell align="right">Trait</TableCell>
                                                            <TableCell align="right">Rarity</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <TableRow
                                                            key="1"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Type
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Type }</TableCell>
                                                            <TableCell align="right">{ bee.Type_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="2"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Background
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Background }</TableCell>
                                                            <TableCell align="right">{ bee.Background_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="3"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Wings
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Wings }</TableCell>
                                                            <TableCell align="right">{ bee.Wings_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="4"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Body
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Body }</TableCell>
                                                            <TableCell align="right">{ bee.Body_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="5"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Mouth
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Mouth }</TableCell>
                                                            <TableCell align="right">{ bee.Mouth_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="6"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Eyes
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Eyes }</TableCell>
                                                            <TableCell align="right">{ bee.Eyes_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="7"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Antenna
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.Antenna }</TableCell>
                                                            <TableCell align="right">{ bee.Antenna_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="8"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Approx. Location
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.ApproxLocation }</TableCell>
                                                            <TableCell align="right">{ bee.ApproxLocation_P } %</TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key="9"
                                                            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                Colony Name
                                                            </TableCell>
                                                            <TableCell align="right">{ bee.ColonyName }</TableCell>
                                                            <TableCell align="right">{ bee.ColonyName_P } %</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Grid>
                            </Grid>
                        </div >
                    );
                }) }
        </Container >
    );
};
