
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, Link, Paper, useTheme } from '@mui/material';
import IntroHexagonImage from './intro-hexagon-image';


// eslint-disable-next-line require-jsdoc
export default function Intro() {
    const theme = useTheme();

    return (
        <Container maxWidth={ false } sx={ theme.custom.lightSectionStyle }>
            <Container maxWidth="lg" sx={ { py: 7 } }>
                <Grid container spacing={ 4 }>
                    <Grid item sm={ 4 } sx={ { minHeight: '200px', width: '100%' } }>
                        <IntroHexagonImage />
                    </Grid>
                    <Grid item sm={ 8 } container
                        alignItems="center">
                        <Box sx={ { p: 1 } }>
                            <Typography variant="h3" component="div" gutterBottom>
                                BEE ARE FAMILY
                            </Typography>
                            <Typography variant="body1" component="div" gutterBottom>
                                We are real beekeepers with 30 bee colonies in the Swiss Alps. With BuzzClub, we aim to bring the crypto community closer to nature. As such, each artistic NFT bee represents a real bee in one of our hives.<p></p>
                                <p />
                                Today bees around the world are suffering and dying from pesticides, parasites and loss of habitat. If we don’t do something soon we will lose up to 75% of our food supply. With this project we aim to raise awareness, give our own colonies an adoptive parent, as well as donate 10% of minting proceeds to charities that aim to save the bees.
                                <p></p>
                                The NFTs are Metaplex compatible and hosted on arweave for eternity on Solana. The Solana blockchain is the ideal home for BuzzClub bees as it is eco-friendly with its low energy usage and gas fees.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}