import * as React from 'react';
import Footer from '../components/Footer';
import RoadMap from '../components/roadmap';
import Faq from '../components/faq';
import Team from '../components/team';
import Rarity from '../components/rarity';
import withRoot from '../modules/withRoot';
import AppBar from '../components/AppBar';
import Landing from '../components/landing';
import Intro from '../components/intro';
import HoneyShop from '../components/honey-shop';
import ObserverPlatform from '../components/observer-platform';

function Index() {
  return (
    <React.Fragment>
      <AppBar />
      <Landing />
      <Intro />
      <HoneyShop />
      <Rarity />
      <ObserverPlatform/>
      <RoadMap />
      <Team />
      <Faq />
      <Footer />
    </React.Fragment>
  );
}

export default withRoot(Index);