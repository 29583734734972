import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MuiToolbar from '@mui/material/Toolbar';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Twitter from '../assets/twitter.svg';
import Discord from '../assets/discord.svg';
import BuzzClubLogo from '../assets/logo_brown_height.svg';
import { Grid, useMediaQuery } from '@material-ui/core';
import theme from '../modules/theme';


function AppBar() {
    // eslint-disable-next-line react/display-name
    const component = React.forwardRef((props, ref) => (
        // @ts-ignore
        <AnchorLink {...props} gatsbyLinkProps={{ ref }} to={props.href} />
    ));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const rightLink = {
        ml: isSmallScreen ? 1 : 3,
    };
    const iconLink = {
        display: 'flex',
        alignItems: 'center',
        m: '5px',
    };

    return (
        <div id="top">
            <MuiToolbar sx={{ justifyContent: 'space-between', bgcolor: theme.palette.primary.main, py: 1 }}>
                <Grid container spacing={0} sx={{ justifyContent: 'center', ml: 0 }}>
                    <Grid item md={3}>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-start', height: '39px' }}>
                            <BuzzClubLogo />
                        </Box>
                    </Grid>
                    <Grid item md={7}>
                        <Box sx={{
                            flex: 1, display: 'flex', justifyContent: 'flex-end',
                            alignItems: 'center', height: '100%'
                        }}>
                            <Link component={component}
                                color="inherit"
                                variant="h5"
                                underline="none" sx={rightLink} href="/#rarity" title="How Rare?">
                                Rarity
                            </Link>
                            <Link component={component}
                                color="inherit"
                                variant="h5"
                                underline="none" sx={rightLink} href="/#roadmap" title="Our Roadmap">
                                Roadmap
                            </Link>
                            <Link component={component}
                                color="inherit"
                                variant="h5"
                                underline="none" sx={rightLink} href="/#team" title="Our team">
                                Team
                            </Link>
                            <Link component={component}
                                color="inherit"
                                variant="h5"
                                underline="none" sx={rightLink} href="/#faq" title="Our FAQ">
                                FAQ
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item md={2}>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                            <Link sx={iconLink} href="https://twitter.com/BuzzClubNFT" target="_blank">
                                <Twitter />
                            </Link>
                            <Link sx={iconLink} href="https://discord.gg/HKj5CHtaZG" target="_blank">
                                <Discord />
                            </Link>
                        </Box>
                    </Grid>
                </Grid>
            </MuiToolbar>
        </div >
    );
}

export default AppBar;
