import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Typography, useTheme, Link, Container, Button, Dialog } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';


export default function Faq() {

    const theme = useTheme();

    const [openHoney, setOpenHoney] = React.useState(false);
    const handleOpenHoney = () => {
        setOpenHoney(true);
    };
    const handleCloseHoney = (value) => {
        setOpenHoney(false);
    };
    const [openPlatform, setOpenPlatform] = React.useState(false);
    const handleOpenPlatform = () => {
        setOpenPlatform(true);
    };
    const handleClosePlatform = (value) => {
        setOpenPlatform(false);
    };
    return (
        <Container maxWidth={ false } id="faq" sx={ theme.custom.lightSectionStyle } >
            <Container maxWidth='lg' sx={ { pt: 7, pb: 9 } }>
                <Typography variant="h2" component="div" gutterBottom align="center">
                    FAQ
                </Typography>
                <Accordion sx={ { ...theme.custom.darkBgColor, borderTopLeftRadius: 10, borderTopRightRadius: 10 } }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>When was the launch day?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            We held a 100% fair minting event on November 2nd 2021. 480 NFTs were be sold for 0.25 SOL in the public mint. There was no pre-sale or whitelist.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={ theme.custom.darkBgColor }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>How do I get a BuzzClub bee?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            You can buy your BuzzClub NFT at 
                            <Button sx= {{color: "#FFFFFF"}} href="https://magiceden.io/marketplace/buzzclub_nft" target="_blank">
                                    Magic Eden
                            </Button>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={ theme.custom.darkBgColor }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>Can I order honey?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                        Yes, you can buy an NFT together with a jar of honey. We will ship you a jar with the NFT printed on it and send the NFT to your Solana walet.                       
                        </Typography>
                        <Button href='https://forms.gle/FzfGYEodsUiPfDv66' target='_blank' variant="contained" sx={ theme.custom.darkButton }>
                                    To The Shop
                        </Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={ theme.custom.darkBgColor }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>Where do BuzzClub bees live?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Our BuzzClub bees reside in the world of Solana, an innovative blockchain with low energy usage and low transaction fees. Each BuzzClub bee represents a real bee in one of our 30 hives in the Swiss Alps.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={ theme.custom.darkBgColor }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>Do I have to take care of my real bee?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            No, the two beekeepers on this project will still own and take care of the real bees for you. However, as a bee holder you will get regular updates on how your bee is doing from the beekeeper and you will be able to watch them via the observer platform.
                            <p></p>
                        </Typography>
                        <Button href='https://observer.buzzclubnft.com/' target='_blank' variant="contained" sx={ theme.custom.darkButton }>
                                    Observe your bee
                        </Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={ theme.custom.darkBgColor }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>What do I own?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            You own the underlying art stored on the blockchain. Full copyrights of it are transferred upon minting. But to ensure the continued health of the real bee and the hive as a whole, the beekeeper remains the owner of the physical bees.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion sx={ theme.custom.darkBgColor }>
                    <AccordionSummary
                        expandIcon={ <ExpandMoreIcon /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header" >
                        <Typography style={ { fontWeight: 600 } }>More questions?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Join our { <Link href="https://twitter.com/BuzzClubNFT" target="_blank" color='secondary'>Twitter</Link> }
                            &nbsp; and { <Link href="https://discord.gg/HKj5CHtaZG" target="_blank" color='secondary'>Discord</Link> }
                            &nbsp; community and ask us there!
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Container>
        </Container >
    );
}