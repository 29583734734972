import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useTheme, Button, Tooltip, Dialog, useMediaQuery, Link } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { StaticImage } from 'gatsby-plugin-image';



// eslint-disable-next-line require-jsdoc
export default function HoneyShop() {
    const theme = useTheme();

    return (
        <Container maxWidth={ false } sx={ theme.custom.darkSectionStyle }>
            <Container maxWidth="lg" sx={ { py: 7 } }>
                <Grid container spacing={ 4 }>
                    <Grid item sm={ 8 } container
                        alignItems="center">
                        <Box sx={ { p: 1 } }>
                            <Typography variant="h3" component="div" gutterBottom>
                                HONEY, GET ME SOME HONEY
                            </Typography>
                            <Typography variant="body1" component="div" gutterBottom>
                                We bring NFTs and real world assets closer together. If you are looking for a unique present for your friends, how about
                                a jar of honey together with an NFT? On the right, you see an example of the honey jar you will receive. With it, we send the
                                NFT on the jar to a wallet of your choosing.
                                <p />
                                With Christmas around the corner, you should not miss this!
                                Whether the gifted person is into crypto or not yet, you surely have a good story to tell.
                                <p />
                                Find out more about this cool opportunity here:
                                <p />
                                <Button href='https://forms.gle/FzfGYEodsUiPfDv66' target='_blank' variant="contained" sx={ theme.custom.darkButton }>
                                    To The Shop
                                </Button>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={ 4 } sx={ { minHeight: '200px', width: '100%' } }>
                        <StaticImage
                            src="../images/honey_jar.png"
                            alt="a bee..."
                            placeholder="blurred"
                            objectFit='contain'
                        />
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}