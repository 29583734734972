import { Box, Grid, Link, Typography, useTheme, Container, Button, Dialog } from '@mui/material';
import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Twitter from '../assets/twitter.svg';
import Discord from '../assets/discord.svg';
import Medium from '../assets/medium.svg';
import Terms from '../components/terms';

export default function Footer() {
    const theme = useTheme();

    const iconLink = {
        display: 'flex',
        alignItems: 'center',
        m: '5px',
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = (value) => {
        setOpen(false);
    };

    return (
        <Container maxWidth={false} sx={theme.custom.darkBgColor}>
            <Container maxWidth="xl" sx={{ py: 1 }}>
                <Grid container spacing={0} sx={{ justifyContent: 'center' }}>
                    <Grid item sm={3}>
                        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', px: 3 }}>
                            <Link sx={iconLink} href="https://twitter.com/BuzzClubNFT" target="_blank">
                                <Twitter />
                            </Link>
                            <Link sx={iconLink} href="https://discord.gg/HKj5CHtaZG" target="_blank">
                                <Discord />
                            </Link>
                            <Link sx={iconLink} href="https://medium.com/@buzzclubnft" target="_blank">
                                <Medium />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box sx={{
                            flex: 1, display: 'flex', justifyContent: 'center',
                            alignItems: 'center', height: '100%',
                        }}>
                            <Typography variant="body2" color="text.secondary">
                                {'Made with'}
                                <FavoriteIcon sx={{ height: '0.5em' }} />
                                {'in Switzerland '}
                                {new Date().getFullYear()}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={3}>
                        <Box sx={{
                            flex: 1, display: 'flex', justifyContent: 'center',
                            alignItems: 'center', height: '100%', px: 3,
                        }}>
                            <Button onClick={handleOpen} color="secondary">Terms & Conditions</Button>
                        </Box>
                    </Grid>
                    <Grid item sm={3}>
                        <Dialog onClose={handleClose} open={open}>
                            <Terms></Terms>
                        </Dialog>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}