import * as React from 'react'
import { Box, useTheme } from '@mui/material';

export default function Terms() {
    const theme = useTheme();
    return (
        <Box sx={theme.custom.termsSectionStyle}>
            <h1>Terms and Conditions</h1>
            <span>BuzzClub is a collection of digital artworks on the Solana Blockchain created by a team of beekeepers, developers and artists. After the initial minting there is a fixed number of individual Solana Tokens (“NFTs”) with traits/attributes that correspond to pieces of art hosted on the arweave (https://www.arweave.org/).
                This website is only an interface allowing participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Solana wallets and validating all transactions and contracts generated by this website. We will not be able to undo, reverse, or restore any transactions, as the contract runs on the Solana blockchain.
                This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting full responsibility for all transactions involving BuzzClub NFTs.
            </span>
            <p></p>
            <h2>Ownership</h2>
            <span>By connecting your Solana wallet and minting an NFT, you gain full and complete ownership of the underlying artwork. At no point may we seize, freeze, or otherwise modify the ownership of any NFT.
                You do not own the physical bees or hives, nor do you have any claims on the products produced by the bees. Only queen bee NFT holders have a right to one jar of honey, which will be shipped internationally after the initial minting event. While we will do our best to make sure that the honey will reach the postal address provided by you, we cannot guarantee delivery of the honey (e.g. due to local import restrictions).
            </span>
            <p></p>
            <h2>License</h2>
            <h3>a) General Use</h3>
            <span>Subject to your continued compliance with the terms of this License, BuzzClub grants you a worldwide, non-exclusive, non-transferable, license to use, copy, and display the Art for your Purchased NFTs, along with any Extensions that you choose to create or use, solely for the following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your NFTs, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your NFTs, provided that the website/application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the website/application.</span>
            <p></p>
            <h3>b) Commercial Use</h3>
            <span>Subject to your continued compliance with the terms of this License, BuzzClub grants you a limited, worldwide, non-exclusive, non-transferable license to use, copy, and display the Art for your Purchased NFTs for the purpose of commercializing your own merchandise that includes, contains, or consists of the Art for your Purchased NFTs (“Commercial Use”). For the sake of clarity, nothing in this Section 3.b will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of NFTs generally, provided that the marketplace cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of NFTs generally, provided that the third party website or application cryptographically verifies each NFT owner’s rights to display the Art for their Purchased NFTs to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFT leaves the website/application.</span>
            <p></p>
            <h2>Fees & Royalties</h2>
            <span>Initial & secondary sales: As with all transactions on the Solana blockchain, you will need to pay a “gas fee” for every transaction on the Solana network. </span>
            <p></p>
            <span>Secondary Sale: If you trade your NFTs on a third party NFT marketplace, you will also be subject to their terms, which may include the payment of commissions. Moreover, we will retain, as a royalty fee, 5% of proceeds from any subsequent sales of the NFT that is sold. You acknowledge and agree that the royalty fee will be transferred directly to us through the Solana network as part of your payment.</span>
            <p></p>
            <h2>No Guarantees or Future Promises</h2>
            <span>You agree that your purchase of a NFT from our initial project is all you are guaranteed to receive with your initial purchase. Any future potential benefits are ancillary to this purchase and not to be taken into consideration with your initial purchase. You agree that you are not relying on any future commitments by BuzzClub in using this site and participating in our NFT launch.</span>
            <p></p>
            <h2>No investment</h2>
            <span>We make absolutely no promise or guarantee that the NFTs will have a monetary value. You understand that they have no inherent monetary value</span>
            <p></p>
        </Box>
    );
}
