import React from 'react';
import { Box } from '@mui/material';


/** @jsx jsx */
import { jsx, css } from '@emotion/react';

const hexagon = css`
  position: relative;
  height: 110px;
  width: 100px;
  background: rgba(0, 0, 0, 0.02);
  margin: 1px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: 2s;
&:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.03);
}
&:hover{
  transition: 0s;
  background: rgba(221, 151, 43, 0.3);
}
`;

const container = css`
position: relative;
overflow: hidden;
animation: animate 4s linear infinite;
`;

const row = css`
display: inline-flex;
margin-left: -50px;
margin-top: -32px;
overflow: hidden;
&:nth-of-type(even){
  margin-left: 1px;
}
`;

const topcontainer = css`
position: absolute;
z-index:1000;
width:100%;
height:100%;
overflow:hidden;
`;

// eslint-disable-next-line require-jsdoc
export default function RoadMapBackground() {
    const myRows = [...Array(32)].map((el, index) => <div key={index} css={hexagon}></div>);
    const myRowList = [];
    for (let i = 0; i < 16; i++) {
        myRowList.push(<div css={row} key={i}>{myRows}</div>);
    }

    return (
        <Box css={topcontainer}>
            <div css={container}>
                {myRowList}
            </div>
        </Box>
    );
}
