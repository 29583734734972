import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useTheme, Button, Tooltip, Dialog, useMediaQuery, Link } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { StaticImage } from 'gatsby-plugin-image';



// eslint-disable-next-line require-jsdoc
export default function ObserverPlatform() {
    const theme = useTheme();

    return (
        <Container maxWidth={ false } sx={ theme.custom.darkSectionStyle }>
            <Container maxWidth="lg" sx={ { py: 7 } }>
                <Grid container spacing={ 4 }>
                    <Grid item sm={ 6 } container
                        alignItems="center">
                        <Box sx={ { p: 1 } }>
                            <Typography variant="h3" component="div" gutterBottom>
                                OBSERVE YOUR BEE
                            </Typography>
                            <Typography variant="body1" component="div" gutterBottom>
                                We want to give you an intimate access to our hives. This motivated us to install a camera which displays live footage from the colonies directly to your home. As of now, the camera has a refresh rate of 5 minutes giving you the opportunity to observe the most up to date hive conditions day and night.
                                <p />
                                How is your adopted bee doing? To answer this question, we equipped a hive with a scale. This piece of technology lets you get a good sense of how successful the bees are in gathering honey. For each time frame that you can observe, we added explanatory context on how you have to interpret giving you the ability to analyse the hive’s state all by yourself all year round.
                                <p />
                                Only holders of BuzzClub NFTs get access to the Observer Platform. If you haven’t already, head over to one of the secondary markets and get one yourself, for instance on 
                                
                                <Button sx= {{color: "#FFFFFF"}} href="https://magiceden.io/marketplace/buzzclub_nft" target="_blank">
                                Magic Eden
                                </Button>
                                </Typography>
                                <Button href='https://observer.buzzclubnft.com/' target='_blank' variant="contained" sx={ theme.custom.darkButton }>
                                    Observe your bee
                                </Button>
                                
                        </Box>
                    </Grid>
                    <Grid item sm={ 6 } sx={ {  width: '100%'} }>
                        <Box sx={ { pt: 10 } }>
                        <StaticImage
                            src="../images/observer.PNG"
                            alt="a bee..."
                            placeholder="blurred"
                            objectFit='contain'
                        />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}